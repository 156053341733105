* {
    margin: 0;
    padding: 0;
}

// ::selection {
//     background-color: #FE938C;
// }

:focus {
    border: 2px solid #fe948c69;
}

@import "navbar.scss";
@import "colors.scss";
@import "typography.scss";
@import "home.scss";
@import "post.scss";
@import "pages.scss";
@import "footer.scss";