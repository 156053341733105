@import "colors.scss";

.page-info {
    margin-top: 2em;
    padding: 0 1.2em;
}

.title {
    margin: 0 auto 0 0;
}

.tagline {
    display: block;
    margin: 0.25em 0;
}

.tagline, .date {
    font-family: 'Josefin Slab', serif;
    font-weight: 400;
}

article, section.social {
    padding: 4em 1.2em;
}

.page-info {
    display: flex;
    justify-content: center;
}

.info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    .date {
        margin-right: 0.25em;
        width: max-content;
    }
    .pipe, .tag {
        margin: 0 0.25em;
    }
}

span.date, .pipe {
    color: $date-color;
}

a.tag {
    text-decoration: none;
    color: $tag-text;
    border-radius: 10px;
    padding: 0 5px;
    background-color: $tag-text-background;
    font-size: small;
    &#archived {
        background-color: $archive-tag-background;
    }
    &:hover{
        background-color: $tag-background-hover;
        color: $tag-text-hover;
        transform: scale(1.2);
    }
}

.blog-post  > article p {
    margin-bottom: 1.2em;
}

// Blockquote CSS with media queries inspired by johnfinkdesign codepen
blockquote{
  border-left: 3px solid $quote-border-color;
  color: $quote-text;
//   font-family: Georgia, Times, "Times New Roman", serif;
//   font-size: 1.25em;
  font-style: italic;
  line-height: 1.5em;
  margin: 1.1em -4em;
  padding: 0.5em 2em;
  position: relative;
  z-index: 0;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -4px;
        height: 2em;
        background-color: white;
        width: 5px;
        margin-top: -1em;
    }
    &:after {
        content: "\201C";
        position: absolute;
        top: 50%;
        left: -0.5em;
        color: $quote-border-color;
        // font-family: "Ionicons";
        font-style: normal;
        line-height: 1.4em;
        text-align: center;
        text-indent: -2px;
        width: 1em;
        margin-top: -0.5em;
    }
}

@media (max-width: 980px){
  blockquote{
    font-size: 1.1em;
    margin: 1.1em -2em;
    }
}

@media (max-width: 767px) {
  blockquote {
    border-top: 2px solid $quote-border-color;
    border-bottom: 2px solid $quote-border-color;
    border-left: none;
    margin: 1.5em 0;
    padding: 1.5em 1em;
        &:before {
        left: 50%;
        top: 0;
        height: 4px;
        margin-top: -3px;
        margin-left: -1em;
        width: 2em;
        }
        &:after {
        font-size: 1.2em;
        left: 50%;
        top: 0;
        margin-top: -0.6em;
        margin-left: -0.5em;
        }
    }
}

//images css
p img{
    display: block;
    margin: 1.5em auto;
    max-width: 100%;
    max-height: 300px;
    text-align: center;
    border-radius: 5px;
}

//post navigation css
div.further-reading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.2em;
}

div.more {
    background: $more-posts-background;
    color: $more-posts-text;
    border-radius: 10px;
    margin-bottom: 0.5em;
    padding: 0.5em;
    transition: all 0.2s ease-in-out;
    .other-post-tagline {
        font-family: 'Marcellus', serif;
    }
    a {
        font-size: 1.2em;
        font-family: 'Sansita Swashed', cursive;
    }
    .arrow {
        font-family: 'Sansita Swashed', cursive;
    }
    &:hover {
        background-color: $more-posts-background-hover;
        color: $more-posts-text-hover;
        a, .arrow {
            color: $more-posts-text-hover;
        }
        transform: scale(1.025);
    }
    .arrow {
        font-weight: bold;
        color: $more-posts-text;
    }
}

#right-arrow {
    float: right;
}

//social buttons
section.social{
    padding-top: 0;
    h5 {
        margin-bottom: 1em;
    }
    a {
        color: white;
        font-size: 1.5em;
        padding: 0.5em;
        &.facebook_share {
            background-color: $facebook-share-background;
        }
        &.twitter_share {
            background-color: $twitter-share-background;
        }
        &.linkedin_share {
            background-color: $linkedin-share-background;
        }
        &.email_share {
            background-color: $email-share-background;
        }
    }
}

//media queries
@media (min-width: 750px){
    article, .page-info {
        margin: 2em auto;
        margin-bottom: 0;
        padding: 0;
        max-width: 700px;
    }

    div.page-info {
        padding: 1.5em 0;
    }

    div.more {
        width: 50%;
    }

    div.further-reading {
        margin: 0 auto;
        max-width: 700px;
        flex-direction: row;
        .more {
            max-width: 45%;
        }
    }

    section.social {
        max-width: 700px;
        margin: 0 auto;
        padding-top: 1.5em;
        h5 {
            margin-bottom: 0.2em;
        }
        a {
            font-size: 1em;
            padding: 0.25em;
        }
        
        i.fa-facebook-official:after{
            content: " Facebook";
            color: black;
        }
        i.fa-twitter:after{
            content: " Twitter";
            color: black;
        }
        i.fa-linkedin:after{
            content: " LinkedIn";
            color: black;
        }
        i.fa-envelope:after{
            content: " Email";
            color: black;
        }
    }
}