//navbar colors
$nav-background: #86b386;
$nav-links-text: white;
$nav-links-hover: #5e7d5e;
$nav-links-hover-text: white;
$subscribe-background: #b38686;
$subscribe-link-text: white;
$subscribe-link-hover: #7d5e5e;
$subscribe-link-hover-text: white;
$active-page-link-background: green;
$active-page-link-text: white;

//home page colors
$latest-posts-background: #86b386;
$latest-posts-cards-background: white;
$latest-posts-cards-text: black;
$latest-posts-cards-background-hover: #efffef;
$latest-posts-cards-text-hover: black;

//colors for page titles
$page-title-background: #b38686;
$page-title-text: white;

//colors for contact page
$form-container-background: #f2f2f2;
$input-text-area: #ccc;
$submit-button-background: #b38686;
$submit-button-text: white;
$submit-button-background-hover: #7d5e5e;
$submit-button-text-hover: white;

//colors for blog page
$blog-post-cards-background: #86b386c4;
$pagination-nav-text: black;
$pagination-current-background: #7d5e5e;
$pagination-pages-background: #b38686;
$pagination-nav-clickable-background: #b38686;
$pagination-elements-text: white;
$pagination-nav-non-clickable-background: lightgray;

//colors for posts
$date-color: grey;
$tag-text: black;
$tag-text-background: pink;
$tag-text-hover: black;
$tag-background-hover: lightblue;
$archive-tag-background: lightgray;
$more-posts-background:#b38686;
$more-posts-text: black;
$more-posts-background-hover: #7d5e5e;
$more-posts-text-hover: white;

$quote-border-color: #b38686;
$quote-text: black;

$facebook-share-background: #4267B2;
$twitter-share-background: #1DA1F2;
$linkedin-share-background: #0e76a8;
$email-share-background: lightgray;

//colors for footer
$footer-background: #86b386;
$footer-link-head-color: white;
$footer-link-text-color: white;
$footer-text-color: white;