@import "colors.scss";

section {
    padding: 2.5em 4em;
}

h2 {
    text-align: center;
    margin-bottom: 1em;
}

.whats-new {
    li {
        margin-top: 1em;
        list-style: "📚  ";
    }
}

.latest {
    background-color: $latest-posts-background;
    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
     }
    article {
        background-color: $latest-posts-cards-background;
        color: $latest-posts-cards-text;
        border-radius: 10px;
        flex-basis: 30%;
        min-width: 300px;
        min-height: 250px;
        margin: 0.55em;
        padding: 0.5em;
        transition: all 0.2s ease-in-out;
        box-shadow: 5px 5px 5px #8888886c;
        h4 {
            font-weight: 400;
            padding: 0.25em 0;
        }
        &:hover {
            background-color: $latest-posts-cards-background-hover;
            color: $latest-posts-cards-text-hover;
            transform: scale(1.025);
        }
    }

}



/* for bigger screens

section {
    padding: 2.5em 4em;
}

*/