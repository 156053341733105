@import "colors.scss";

//This is the stylesheet for general pages styling and unique styling for individual pages

//all pages
.hero {
  background-image: url("/assets/hero-image.jpg");
  background-size: cover;
  width: 100%;
  height: 47vh;
}

section.content {
    padding: 4em 1.2em;
}

div.hero h1 {
  position: relative;
  top: 34%;
  margin: 0 auto;
  padding: 0.25em;
  padding-bottom: 0.5em;
  background-color: $page-title-background;
  color: $page-title-text;
  border-radius: 5px;
  width: max-content;
}

p {
  margin-bottom: 1.2em;
}

//this is for the contact page
.page-content {
    margin: 0;
    padding: 0;
}

.form-container {
    margin: 2em auto;
    max-width: 700px;
}

input[type=text], input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid $input-text-area;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input[type=submit] {
    background-color: $submit-button-background;
    color: $submit-button-text;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: $submit-button-background-hover;
    color: $submit-button-text-hover
  }
  
  .form-container {
    border-radius: 5px;
    background-color: $form-container-background;
    padding: 20px;
  }

  
  //this is for the published work page
  section.content ul {
    margin: 1.5em;
  }
  
  section.content ul li {
    margin-top: 1em;
  list-style: "📚 ";
}

section.content ul:not(.tag-list):last-of-type li {
  list-style: "🏆 ";
}

h3#my-novels, h3#short-stories, h3#awards-and-recognition {
  margin-top: 2em;
}

//this is for the blog list page
.post-list-card {
    margin: 0;
    margin-bottom: 3em;
    padding: 0.5em;
    background-color: $blog-post-cards-background;
    border-radius: 10px;
}

div.pagination {
  text-align: center;
  span, a {
    margin: 0 -3px;
    padding: 0.5em;
    border: 1px solid gray;
  }
  .paginator-nav {
    color: $pagination-nav-text;
  }
  .current-paginator-page {
    background-color: $pagination-current-background;
    color: $pagination-elements-text;
  }
  .paginator-pages {
    background-color: $pagination-pages-background;
    color: $pagination-elements-text;
  }
  .paginator-nav-active {
    background-color: $pagination-nav-clickable-background;
    color: $pagination-elements-text;
  }
  .paginator-nav-inactive {
    background-color: $pagination-nav-non-clickable-background;
  }
}

div.pagination > :first-child {
  border-radius: 5px 0 0 5px;
}
div.pagination > :last-child {
  border-radius: 0 5px 5px 0;
}

//This is the styling for the tag page
.tag-title {
  text-align: left;
  margin: 1.5em 0 0.5em 0;
  &:target {
    &:after {
      content: "🏷️"
    }
  }
}

//media queries

@media (min-width: 650px){
    section.content {
        margin: 2em auto;
        margin-bottom: 0;
        padding-bottom: 0;
        max-width: 700px;
    }
}

@media (min-width: 880px){
  section.content ul {
    margin: 0;
    padding: 0;
  }
  
}